<template>
    <div style="padding: 0px" class="container-fluid">
        <div style="border: 0px" class="card" v-if="center != null">
            <div style="border: 0px" class="card-body1">
                <div class="col-md-12 text-center" style="height: 100vw; margin-top: 100px" v-if="$route.fullPath == '' || $route.fullPath == '/'">
                    <h3>Welcome</h3>
                    <p>You need to visit on following link.</p>
                    <router-link tag="a" to="/staff/login" class="btn btn-success m-1">Staff Login</router-link>
                    <router-link tag="a" to="/customer/register" class="btn btn-success m-1">Customer Login / Register</router-link>
                </div>
                <router-view></router-view>
            </div>
        </div>
        <div v-if="center == null" style="background-color: #00259a">
            <div class="card1" no-body>
                <div class="card-body">
                    <img src="../assets/images/logocentumx.png" alt="" />
                    <p class="text-center">
                        CentumX is an advanced leisure management software, designed to improve the way sport and recreational activities are managed. Our vision is to empower users, providing them
                        with a sweamless and enhanced leisure management experience.
                    </p>
                    <h6 class="text-center mt-5">Select Center</h6>
                    <div class="mt-3" style="display: flex; flex-direction: row; justify-content: space-between; padding-bottom: 2rem">
                        <b-select class="select" v-model="selected_center">
                            <option :value="o" v-for="(o, i) in list" :key="i">{{ o.center_name }}</option>
                        </b-select>
                        <button @click="navigatetologin()" class="btn btn-action">Submit</button>
                    </div>
                </div>
                <p class="text-center mt-5 mb-0 p-0">Copyright 2024 centumX</p>
            </div>
        </div>
    </div>
</template>

<script>
    import '@/assets/sass/authentication/auth.scss';
    import { mapGetters } from 'vuex';
    import axios from 'axios';

    export default {
        name: 'Home',
        data() {
            return {
                baseurl: this.$appSetting.hostname,
                title: '',
                list: [],
                selected_center: null,
                reset_center_Id: null,
                currentUrl: '',
                token: '',
            };
        },
        metaInfo() {
            const ctr = this.title;
            return {
                title: `${ctr}`,
            };
        },
        created() {
            // this.initializeRouteData();
            this.getallcenterlist();
        },
        mounted() {
            // this.initializeRouteData();
            this.getallcenterlist();
        },

        methods: {
            initializeRouteData() {
                if (!this.$router || !this.$router.history) {
                    console.error('Vue Router is not initialized or available.');
                    return;
                }
                try {
                    console.log('Current URL: pathname', window.location.pathname);
                    console.log('Current URL: fullpath', this.$router.history.current.fullPath);
                    const currentUrl = this.$router.history.current ? this.$router.history.current.fullPath : window.location.pathname;
                    console.log('Current URL: url', currentUrl);
                    const parts = currentUrl.split('/').filter((item) => item !== '');
                    console.log('Path Parts:', parts);
                    const reset_center_Id = parts[parts.length - 1];
                    const reset_path = parts[parts.length - 3];
                    console.log('Reset Center ID:', reset_center_Id);
                    console.log('Reset Token:', reset_path);
                    this.handleRouting(reset_path, reset_center_Id);
                } catch (error) {
                    console.error('An error occurred while initializing route data:', error);
                }
            },
            handleRouting(reset_path, reset_center_Id) {
                console.log('reset token', reset_path);
                console.log('reset center Id', reset_center_Id);
                if (this.$store.state.center == null && this.center == null && window.location.host === 'app.centumx.ie') {
                    // if (reset_path === 'resetpassword' && reset_center_Id != null && reset_center_Id !== undefined) {
                    //     console.log('Conditions met. Staying on the reset page>>>>>>.');
                    // } else {
                    console.log('Redirecting to select center page.');
                    this.$router.push('/staff/select_center');
                    // }
                } else if (reset_path === 'resetpassword' && reset_center_Id != null && reset_center_Id !== undefined) {
                    console.log('Conditions met. Staying on the reset page>>>>>>.');
                } else {
                    if (!this.$store.state.center) {
                        axios
                            .get('getCenterDetails/' + window.location.host)
                            .then((res) => {
                                if (res.data.error) {
                                    console.log(res);
                                    if (res.data.data == null) {
                                        this.$router.push('/staff/select_center');
                                    }
                                } else {
                                    this.$store.dispatch('center', res.data.data);
                                    if (res.data.data == null && window.location.host == 'app.centumx.ie') {
                                        this.$router.push('/staff/select_center');
                                    }
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    } else {
                        console.log('Already have center data. Proceeding::::::2');
                    }
                }
            },
            getallcenterlist() {
                axios
                    .get('get_all_center')
                    .then((res) => {
                        if (res.data.error) {
                            console.log(res);
                        } else {
                            console.log(res);
                            this.list = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            navigatetologin() {
                this.$store.dispatch('center', this.selected_center);
                this.$router.push('/staff/login');
            },
        },
        computed: {
            ...mapGetters(['center']),
            pathname() {
                return window.location.pathname;
            },
        },
        watch: {
            $route() {
                this.initializeRouteData();
            },
        },
    };
</script>
<style scoped>
    img {
        width: 30%;
        height: 30%;
        margin: 2% 35%;
    }
    .card1 {
        padding: 4rem 2rem 2rem;
        width: 44%;
        margin: 0% 28%;
        height: 100%;
        background-color: white;
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .select {
        width: 65%;
        margin-right: 5%;
    }
    .btn.btn-action {
        background: linear-gradient(180deg, #0a3ecb 0%, rgba(74, 187, 238, 0.98) 100%) !important;
        box-shadow: none;
        cursor: pointer;
        padding: 5px 8px;
        border-radius: 6px;
        color: #fff !important;
        width: 30%;
    }
</style>
